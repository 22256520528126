import React from "react";
import clientConfig from "../../client-config";
import PropTypes from "prop-types";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import BackgroundImage from "gatsby-background-image";
import CTALink from "./cta-link";

const Hero = ({ heading, backgroundImage, cta }) => {
  const fluidProps = getFluidGatsbyImage(
    backgroundImage.asset._id,
    { maxWidth: 1920 },
    clientConfig.sanity
  );
  const backgroundImageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))`,
    fluidProps,
  ];
  return (
    <BackgroundImage
      Tag="section"
      className="flex flex-col justify-center items-center p-4"
      fluid={backgroundImageStack}
      backgroundColor={`#040e18`}
      style={{ minHeight: "60vh" }}
    >
      <p className="text-center items-center content-center justify-center uppercase font-semibold text-4xl text-white mx-4 p-2">
        {heading}
      </p>
      {cta && cta.title && (
        <CTALink
          {...cta}
          buttonActionClass="hover:underline bg-white text-gray-800 font-semibold rounded-full my-6 py-4 px-8 shadow-lg mx-4"
        />
      )}
    </BackgroundImage>
  );
};

Hero.propTypes = {
  heading: PropTypes.string,
  backgroundImage: PropTypes.object,
  cta: PropTypes.object,
};

export default Hero;
