import React from 'react';
import PropTypes from 'prop-types';
import ServiceCard from './service-card';
import Heading from './heading';

const ServiceList = ({heading, items}) => (
  <section className="px-8 max-w-screen-xl p-4 mx-auto md:px-8">
    <Heading heading={heading} id="diensten" />
    <div className="flex flex-wrap mt-4 -mx-4">
      {items.filter(s => !s.disabled).map(service => <ServiceCard key={service._key} {...service} />)}
    </div>
  </section>
);

ServiceList.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.array
};

export default ServiceList;