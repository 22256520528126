import React from "react";
import PropTypes from "prop-types";
import Heading from "./heading";

const ContactForm = ({ heading }) => (
  <section className="px-8 max-w-screen-xl p-4 mx-auto md:px-8">
    <Heading heading={heading} id="contact" />
    <form
      className="mx-auto md:w-1/2"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <label
        className="block mb-2 text-xs font-bold uppercase"
        htmlFor="last-name"
      >
        Naam
      </label>
      <input
        className="w-full mb-6 form-input"
        id="last-name"
        type="text"
        placeholder="Naam"
        name="naam"
      />

      <label
        className="block mb-2 text-xs font-bold uppercase"
        htmlFor="first-name"
      >
        Telefoonnummer*
      </label>
      <input
        className="w-full mb-6 form-input"
        id="first-name"
        type="text"
        placeholder="Telefoonnummer"
        required
        name="telefoonnummer"
      />

      <label className="block mb-2 text-xs font-bold uppercase" htmlFor="email">
        E-mail*
      </label>
      <input
        className="w-full mb-6 form-input"
        id="email"
        type="e-mail"
        placeholder="E-mail"
        required
        name="email"
      />

      <label
        className="block mb-2 text-xs font-bold uppercase"
        htmlFor="message"
      >
        Uw bericht/offerteaanvraag*
      </label>
      <textarea
        className="w-full mb-6 form-textarea"
        id="message"
        placeholder="Uw bericht"
        rows="8"
        required
        name="bericht"
      />

      <button
        type="submit"
        className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600"
      >
        Verzenden
      </button>
    </form>
  </section>
);

ContactForm.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default ContactForm;
