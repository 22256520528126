import React from "react";
import PropTypes from "prop-types";

import Hero from "./hero";
import GraphQLErrorList from "./graphql-error-list";
import SEO from "./seo";
import Layout from "./layout";
import ContactForm from "./contact-form";
import ServiceList from "./service-list";
import ProjectPreviewList from "./project-preview-list";

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c) => {
      let el = null;
      switch (c._type) {
        case "hero":
          el = <Hero key={c._key} {...c} />;
          break;
        case "serviceCollection":
          el = <ServiceList key={c._key} {...c} />;
          break;
        case "contactForm":
          el = <ContactForm key={c._key} {...c} />;
          break;
        case "projectCollection":
          el = <ProjectPreviewList key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <Layout>
      <SEO title={data.site.title} />
      {content}
    </Layout>
  );
};

Page.propTypes = {
  errors: PropTypes.array,
  data: PropTypes.object,
};

export default Page;
