import React from "react";
import PropTypes from "prop-types";

import { Link, navigate } from "gatsby";

const doNavigate = target => {
  if (!target || !target.length) {
    return;
  }
  const internal = /^\/(?!\/)/.test(target);
  if (internal) {
    navigate(target);
  } else {
    window.location = target;
  }
};

const CTALink = props => {
  let link = props.route || props.link || "#";

  if (props.kind === "button") {
    return (
      <button
        id="navAction"
        onClick={() => doNavigate(link)}
        className={props.buttonActionClass || ""}
      >
        {props.title}
      </button>
    );
  }

  // External
  if (props.link) {
    return (
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        {props.title}
      </a>
    );
  }

  return (
    <Link className="mr-3" to={link}>
      {props.title}
    </Link>
  );
};

CTALink.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  link: PropTypes.string,
  kind: PropTypes.string,
  buttonActionClass: PropTypes.string,
};


export default CTALink;
