import React from "react";
import PropTypes from "prop-types";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import PortableText from "./portable-text";

const ServiceCard = ({ heading, image, tagline, slug }) => {
  return (
    <div id={slug.current} className="w-full md:w-1/3 flex flex-col mb-8 px-3">
      <div className="overflow-hidden bg-white rounded-sm shadow hover:shadow-raised hover:translateY-2px transition h-full">
        <div className="relative pb-2/3">
          {image && image.asset && (
            <img
              className="absolute h-full object-cover"
              src={imageUrlFor(buildImageObj(image))
                .width(750)
                .height(Math.floor((9 / 16) * 750))
                .fit("crop")
                .auto("format")
                .url()}
              alt={image.alt}
            />
          )}
        </div>
        <div className="p-8">
          <h3 className="text-blue-branding font-bold text-lg text-center">
            {heading}
          </h3>
          <PortableText
            blocks={tagline}
            className="text-gray-700 text-center mt-4 "
          />
        </div>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.object,
  tagline: PropTypes.array,
  slug: PropTypes.object,
};

export default ServiceCard;
